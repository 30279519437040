<template>
  <div class="d-flex align-center profile-menu-wrap">
    <v-menu offset-y min-width="120" attach="#profileMenu" v-model="menuActive">
      <template v-slot:activator="{ on, attrs }">
        <div class="trigger d-flex align-center" v-bind="attrs" v-on="on">
          <v-avatar size="35px">
            <v-img
              v-if="user && user.authUser.picture"
              :src="user.authUser.picture"
            >
              <template #placeholder>
                <v-icon size="35px" color="vl8b8b8b">mdi-account-circle</v-icon>
              </template>
            </v-img>
            <div v-else class="d-flex align-center justify-center">
              <v-icon class="BG" size="35px" color="vl8b8b8b"
                >mdi-account-circle</v-icon
              >
            </div>
          </v-avatar>
          <block-text
            v-if="$vuetify.breakpoint.mdAndUp"
            :text="user.user.fullName"
            class="wallet-id ml-2"
            color="primary"
            weight="bold"
            type="body-1"
          />
          <ph-caret-down
            :size="18"
            class="caret-down mx-2"
            :class="menuActive ? 'expanded' : ''"
            :color="$vuetify.theme.dark ? '#fff' : '#222'"
          ></ph-caret-down>
        </div>
      </template>
      <v-list>
        <v-list-item>
          <v-btn plane text @click="goToWebApp">Tatatu</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn plane text @click="goToShopify">Shop</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn plane text @click="logout">Logout</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <div id="profileMenu"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PhCaretDown } from "phosphor-vue";
export default {
  components: {
    PhCaretDown,
  },
  data() {
    return {
      menuActive: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
    }),
  },
  methods: {
    ...mapActions({
      getECommerceUrl: "wallet/getECommerceUrl",
    }),
    async goToWebApp() {
      location.href = process.env.VUE_APP_SSO;
    },
    async goToShopify() {
      const shopifyUrl = await this.getECommerceUrl({
        callback: process.env.VUE_APP_REDEEM,
      });

      location.href = shopifyUrl;
    },
    async logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-menu-wrap {
  position: relative;
}

#profileMenu {
  position: absolute;
  bottom: 20px;
  right: 120px;
}
.v-menu__content {
  box-shadow: $box-shadow-light;
  border-radius: 8px;
}

.v-menu__content {
  &.theme--dark {
    box-shadow: $box-shadow-dark !important;
  }
}

.caret-down {
  transition: all 0.2s ease-in-out;
  &.expanded {
    transform: rotate(180deg);
    transform-origin: center center;
  }
}
.v-list-item {
  min-height: 40px;
  padding: 0;
  .v-btn {
    width: 100%;
  }
}

.trigger {
  ::v-deep svg {
    polyline {
      stroke-width: 26;
    }
  }
}
</style>
